<template>
  <div>
    <div class="card mb-4 custom-card" style="z-index: 0">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-7 mb-4">
            <b-dropdown id="dropdown-1" text="สถานะ"
                        class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
              <ul style="list-style-type: none; padding-left: 0px;">
                <li v-for="(v) in criteria.status_filters" :key="`filter_status-${v.id}`" style="padding: .5rem 1rem; font-size: 0.875rem;">
                  <input type="checkbox" class="checkbox_search_input" :name="`checkbox_status_filter${v.id}`"
                    :id="`checkbox_status_filter${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                  <label class="form-check-label" v-bind:for="`checkbox_status_filter${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                </li>
              </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="ร้านค้า"
                        class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
              <ul style="list-style-type: none; padding-left: 0px;">
                <li v-for="(v) in criteria.customer_filters" :key="`filter_customer-${v.id}`" style="padding: .5rem 1rem; font-size: 0.875rem;">
                  <input type="checkbox" class="checkbox_search_input" :name="`checkbox_customer_filter${v.id}`"
                    :id="`checkbox_customer_filter${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                  <label class="form-check-label" v-bind:for="`checkbox_customer_filter${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                </li>
              </ul>
              </b-dropdown-text>
            </b-dropdown>
            <b-dropdown id="dropdown-1" text="ลูกค้า"
                        class="dropdown_input_search ml-1 mb-1">
              <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
              <ul style="list-style-type: none; padding-left: 0px;">
                <li v-for="(v) in criteria.store_filters" :key="`filter_store-${v.id}`" style="padding: .5rem 1rem; font-size: 0.875rem;">
                  <input type="checkbox" class="checkbox_search_input" :name="`checkbox_store_filter${v.id}`"
                    :id="`checkbox_store_filter${v.id}`" v-model="v.selected" @change="searchHandler()"/>
                  <label class="form-check-label" v-bind:for="`checkbox_store_filter${v.id}`">{{v.name}} ({{v.filter_count}})</label>
                </li>
              </ul>
              </b-dropdown-text>
            </b-dropdown>
          </div>
          <div class="col-sm-5">
            <b-input-group >
              <b-input-group-append>
                <b-input-group-text  class="input_border_radius_left_top_bt">
                  <b-icon icon="search" />
                </b-input-group-text>
              </b-input-group-append>
              <b-form-input placeholder="ระบุการค้นหา เช่น ชื่อลูกค้า"
                            v-model="criteria.search"
                            class="form-control-group"
                            @keyup.enter.native="searchHandler()">
              </b-form-input>
              <b-input-group-append>
                <b-button size="md"
                          text="Button"
                          variant="primary"
                          @click="searchHandler()"
                          class="btn_border_radius_right_top_bt"
                          style="width: 86px;">
                  ค้นหา
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12">
            <hr class="mt-5 mb-5">
          </div>
          <div class="col-lg-4 col-md-6">
            <span style="font-size: 12px; color: #848484; font-weight: lighter;" class="m-2">
              จำนวน: <strong style="color: #000000; font-weight: bold;">{{countFilter()}} รายการ</strong> จาก {{paging.rowTotal | numberIntFormat}} รายการ
            </span>
          </div>
          <div class="col-md-6">
            <template v-for="(select, index) in criteria.status_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`status_display-${index}`" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.customer_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`customer_display-${index}`" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
            <template v-for="(select, index) in criteria.store_filters">
              <span class="badge bage_selected_checkbox_search m-2" :key="`store_display-${index}`" v-if="select.selected">
                {{select.name}}
                <span class="ml-5" style="font-size: 8px; color: #A5A5A5;">{{select.filter_count}}</span>
                <span class="ml-3" style="font-size: 8px; color: #1C9EE7;" @click="unSelectFilter(select)">
                  <i class="fa fa-times icon-del" style="width: 10px; background: #B3DFF8; border-radius: 10px; height: 10px; position: relative; top: -1px;"></i>
                </span>
              </span>
            </template>
          </div>
          <div class="col-lg-2 col-md-6">
            <div class="label_algin_right text-underline m-2" style="color: #848484; cursor: pointer;" @click="unSelectAllFilter()">ล้างทั้งหมด</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="card custom-card">
        <div class="card-body">
          <div class="table-responsive padding_top_20">
            <table class="table align-middle table-bordered">
              <thead>
                <tr class="text-center" >
                  <th>รหัสการสั่งซื้อ</th>
                  <th>ชื่อผู้ขาย</th>
                  <th>ชื่อร้านค้า</th>
                  <th>ชื่อลูกค้า</th>
                  <th>สถานะ</th>
                  <th>วันที่สั่งซื้อ</th>
                  <th>ราคารวม</th>
                  <th>ราคาเริ่มต้น</th>
                  <th>ราคาเพิ่ม</th>
                  <th>หมายเหตุ</th>
                  <th style="min-width: 50px; width: 1px;">ดำเนินการ</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(data, index) in items" :key="`list_upsale_${data.id}_${index}`" class="text-center">
                  <td>
                    <a href="javascript:void(0)" class="nav-link text-underline" @click="onViewHandler(data.id)"> {{data.order_code}}</a>
                  </td>
                  <td>{{data.sale_name}}</td>
                  <td>{{data.store_name}}</td>
                  <td>{{data.customer_name}}</td>
                  <td class="text-center">
                    <span class="style_icon_btn">
                      <i :class="StringUtils.getIconUpsaleStatus(data.approve_status)" :title="StringUtils.getTextUpsaleStatus(data.approve_status).text"></i>
                    </span>
                  </td>
                  <td>{{data.date_order_short}}</td>
                  <td class="text-right">{{data.total | number-decimal-format}}</td>
                  <td class="text-right">{{data.upsell_start | number-decimal-format}}</td>
                  <td class="text-right">{{data.upsell_addon | number-decimal-format}}</td>
                  <td class="text-right">{{data.upsell_remark}}</td>
                  <td>
                    <span class="style_icon_btn" v-if="data.approve_status==0&&permission.actionData.flag_update">
                      <i class="fas fa-check-circle text-primary" @click="onEditHandlerConfirmed(data)" title="ยืนยัน"></i>
                    </span>
                    <span class="style_icon_btn" v-if="data.approve_status==0&&permission.actionData.flag_update">
                      <i class="fas fa-times-circle text-danger" @click="onEditHandlerReject(data)" title="ปฏิเสธ"></i>
                    </span>
                  </td>
                </tr>
                <tr v-if="items.length === 0" class="text-center">
                  <td colspan="8">ไม่มีข้อมูล</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card mb-4 mt-4 custom-card">
        <div class="card-body ">
          <div class="row">
            <div class="col-md-3">
              <h3>สถานะ</h3>
              <div v-for="(v, index) in upsaleStatus" :key="`list_upsale_status_${index}`">
                <i :class="StringUtils.getIconUpsaleStatus(v.id)"></i> {{v.id}} "{{v.text}}"
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row padding_top_20">
        <div  class="col-lg-1 col-md-2 col-3"
              style="margin-top: 30px;">
          <select class="form-control mb-2"
                  id="pageLimit"
                  style="width: 80px;"
                  v-model="paging.limit" v-on:change="changeLimit()">
            <option v-for="(data) in paging.pages" :key="data" :value="data">
              {{data}}
            </option>
          </select>
        </div>
        <div class="col-lg-11">
          <div class="row g-3"  v-if="paging.rowTotal > 0">
            <div  class="col-xl-11 col-lg-10 mb-2 text-right"
                  style="margin-top: 30px;">
              <label  class="form-label"
                      style="margin: 8px; font-size: 12px;
                      font-family: Sarabun-Light;
                      color: #818181;">
                แสดงรายการที่ <strong>{{((paging.page-1)*paging.limit)+1}} ถึง {{(paging.page) * paging.limit}}</strong> จาก <strong>{{paging.rowTotal | numberIntFormat}} รายการ</strong>
              </label>
              <paginate v-model="paging.page"
                        :first-last-button="true"
                        :page-count="paging.rowTotal"
                        :page-range="5"
                        :margin-pages="2"
                        :prev-text="'&lt;'"
                        :next-text="'&gt;'"
                        :first-button-text="'&verbar;&lt;'"
                        :last-button-text="'&gt;&verbar;'"
                        :click-handler="changePage"
                        :container-class="'pagination pagination-sm float-right m-auto paging_ul'"
                        :page-class="'page-link'"
                        :prev-class="'page-link prev_paging'"
                        :next-class="'page-link next_paging'">
              </paginate>
            </div>
            <div class="col-xl-1 col-lg-2 padding_left_none">
              <label  class="form-label"
                      style="font-size: 12px;
                      font-family: Sarabun-Light;">
                Go to page
              </label>
              <Select2 v-model="paging.page" :options="paging.pageSizes" @change="changePage(paging.page)"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dialog-approve :title="title"
                    :msg="msg"
                    :approve="approve"
                    ref="dialog_approve"
                    :item="item" @completed="getListItems"/>
  </div>
</template>
<script>
  import $ from 'jquery';
  import mock from '../mock/upsale';
  import dialogApprove from './dialog.approve';
  import StringUtils from '../../../util/StringUtils';
  import Constants from '../../../util/constants';
import Config from '../../../util/config';
  export default {
    name: 'upsale-view-page-upsale',
    data() {
      return {
        permission:{
          appSlug: 'upsale',
          actionData: {}
        },
        StringUtils: StringUtils,
        upsaleStatus: Constants.upsale_status,
        criteria: {
          search: "",
          orderBy: "",
          ascDesc: "",
          status_filters: [],
          customer_filters: [],
          store_filters: []
        },
        paging: {
          page: 1,
          limit: Config.pagination.limit,
          pages: Config.pagination.pages,
          rowTotal: 0,
          pageTotal: 0,
          pageSizes: []
        },
        items: [],
        mock: mock,
        title: 'ยืนยันการอัพเซลล์',
        msg: 'ต้องการยืนยันการอัพเซลล์สินค้านี้หรือไม่ ?',
        approve: true,
        item: {
          remark: '',
          amount_approve: '',
          amount_upsale: ''
        }
      };
    },
    methods: {
      async onViewHandler (id) {
        this.SessionStorageUtils.setSession("page-upsale", id);
        this.$router.push(`/upsale/product/${id}`);
      },
      changePage (page) {
        this.paging.page = parseInt(""+page);
        this.getListItems();
      },
      countFilter(){
        const filters = this.criteria.status_filters.filter((v)=>v.selected);
        const customerFilters = this.criteria.customer_filters.filter((v)=>v.selected);
        const storeFilters = this.criteria.store_filters.filter((v)=>v.selected);
        return filters.length + customerFilters.length + storeFilters.length;
      },
      unSelectFilter(item){
        item.selected = false;
        this.searchHandler();
      },
      unSelectAllFilter(){
        this.criteria.status_filters.map((v)=>{
          v.selected = false;
        });
        this.criteria.customer_filters.map((v)=>{
          v.selected = false;
        });
        this.criteria.store_filters.map((v)=>{
          v.selected = false;
        });
        this.searchHandler();
      },
      async getFilterGroups(){
        const result = await this.HttpServices.getData(`/ab_upsale/getCountFilter`);
        const status = result.data.status;
        this.criteria.customer_filters = result.data.customer;
        this.criteria.store_filters = result.data.store;

        this.criteria.status_filters = [];
        this.upsaleStatus.forEach((v)=>{
          let lst = null;
          status.forEach((vv)=>{
            if(vv.id==v.id){
              lst = vv;
            }
          });
          let filterCount = 0;
          if(lst){
            filterCount = lst.filter_count;
          }
          this.criteria.status_filters.push({id: v.id, name: v.text, filter_count: filterCount});
        });
      },
      changeLimit(){
        this.paging.page = 1;
        this.getListItems();
      },
      sortHandler(orderBy){
        if(orderBy!=this.criteria.orderBy){
          this.criteria.ascDesc = "asc";
        }else{
          this.criteria.ascDesc = this.criteria.ascDesc=="asc"?"desc":"asc";
        }
        this.criteria.orderBy = orderBy;
        this.getListItems();
      },
      searchHandler(){
        this.paging.page = 1;
        this.getListItems();
      },
      async getListItems () {
        let filters = [];
        this.criteria.status_filters.map((v)=>{
          if(v.selected){
            filters.push(v.id);
          }
        });
        filters = filters.join(",");
        let customerFilters = [];
        this.criteria.customer_filters.map((v)=>{
          if(v.selected){
            customerFilters.push(v.id);
          }
        });
        customerFilters = customerFilters.join(",");
        let storeFilters = [];
        this.criteria.store_filters.map((v)=>{
          if(v.selected){
            storeFilters.push(v.id);
          }
        });
        storeFilters = storeFilters.join(",");
        let params = `search=${this.criteria.search}&statusFilters=${filters}&customerFilters=${customerFilters}&storeFilters=${storeFilters}`;
        params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
        const result = await this.HttpServices.getData(`/ab_upsale?${params}`);
        if(result&&result.status.code=="200"){
          const data = result.data;
          var total = Math.ceil(data.rowTotal/this.paging.limit);
          this.paging.pageTotal = total;
          this.paging.rowTotal = data.rowTotal;
          this.items = data.data;
          this.paging.pageSizes = [];
          for(let i=1;i<=total;i++){
            this.paging.pageSizes.push({id: i, text: i});
          }
        }else{
          this.paging.pageSizes = [];
          this.paging.rowTotal = 0;
          this.items = [];
        }
      },
      async onEditHandlerConfirmed (data) {
        this.title = 'ยืนยันการอัพเซลล์';
        this.msg = 'ต้องการยืนยันการอัพเซลล์สินค้านี้หรือไม่ ?';
        this.approve = true;
        this.item.id = data.id;
        this.item.amount_upsale = data.total;
        this.$refs.dialog_approve.$refs['dialog_approve'].show();
      },
      async onEditHandlerReject (data) {
        this.item.id = data.id;
        this.title = 'ปฏิเสธการอัพเซลล์';
        this.msg = 'ต้องการปฏิเสธการอัพเซลล์สินค้านี้หรือไม่ ?';
        this.approve = false;
        this.$refs.dialog_approve.$refs['dialog_approve'].show();
      }
    },
    components: {
      dialogApprove
    },
    async mounted () {
      await this.PermissionServices.viewPermission(this.permission.appSlug, this.$router);
      this.permission.actionData = await this.PermissionServices.getPermission(this.permission.appSlug);
      this.SessionStorageUtils.deleteSession("page-upsale");
      await this.getFilterGroups();
      await this.getListItems();
    }
  };
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
</style>
