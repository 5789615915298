<template>
  <div>
    <b-modal :id="`dialog_approve`" ref="dialog_approve" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-pen"></i> {{title}}</h3>
      </template>
      <div class="text-center">
        <p>{{msg}}</p>
        <div class="row" v-if="approve">
          <div class="col-12">
            <div class="row">
              <div class="col-4 label_algin_right mb-4">
                <label>จำนวนเงินอัพเซลล์</label>
              </div>
              <div class="col-5 mb-4">
                <base-input name="amount_upsale"
                            placeholder="จำนวนเงินอัพเซลล์"
                            v-model="item.amount_upsale"
                            disabled
                            v-decimal-format></base-input>
              </div>
              <div class="col-4 label_algin_right mb-4">
                <label>จำนวนเงินที่อนุมัติ</label>
              </div>
              <div class="col-5 mb-4">
                <base-input name="amount_approve"
                            placeholder="จำนวนเงินที่อนุมัติ"
                            v-model="item.amount_approve"

                            v-decimal-format></base-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="!approve">
          <div class="col-3 label_algin_right">
            <label>หมายเหตุ</label>
          </div>
          <div class="col-7">
            <b-form-textarea name="remark" placeholder="หมายเหตุ" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="saved()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'delivery-view-dialog-approve',
  data () {
    return {}
  },
  props: [
    'title',
    'msg',
    'approve',
    'item'
  ],
  methods: {
    async saved () {
      let params = {};
      if(this.approve){
        if(!this.item.amount_approve){
          this.AlertUtils.alert("warning", "กรุณาระบุจำนวนเงินที่อนุมัติ");
          return;
        }
        params = {
          status: 1,
          price_end: this.item.amount_approve,
          remark: null
        };
      }else{
        if(!this.item.remark){
          this.AlertUtils.alert("warning", "กรุณาระบุหมายเหตุ");
          return;
        }
        params = {
          status: 2,
          price_end: null,
          remark: this.item.remark
        };
      }
      const result = await this.HttpServices.putData(`/ab_upsale/${this.item.id}`, params);
      if(result&&result.status.code=="200"){
        this.AlertUtils.alert("success", `${this.title}สำเร็จ`);
      this.$emit('completed', this.approve);
      this.$bvModal.hide(`dialog_approve`);
      }else{
        this.AlertUtils.alert("error", result.status.message);
      }
    },
    close () {
      this.$bvModal.hide(`dialog_approve`);
    }
  }
}
</script>
