<template>
  <div>
    <base-header class="pb-7 pt-md-6 bg-gradient-dark"></base-header>
    <div class="order-xl-1">
      <div class="card">
        <div class="card-header">
          <b-row align-v="center" slot="header" >
            <b-col cols="8">
              <h2 class="mb-0"><i class="fas fa-check-circle"></i> ข้อมูลการอัพเซลล์ </h2>
            </b-col>
          </b-row>
        </div>

        <div class="card-body">
          <page-upsale/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import pageUpsale from "./view/page.upsale.vue";
  export default {
    name: 'upsale-index',
    components: {
      pageUpsale
    }
  };
</script>
<style>

</style>
